import './App.css';
import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import HOALegalSite from './images/Optimized-hoa-site.png';
import RestaurantSite from './images/Optimized-restaurant-site.png'
import DreamHomes from './images/Optimized-dream-homes.png';
import BlahbbleNews from './images/Optimized-blahbble-news.png';
import EatDrink from './images/Optimized-eatdrink.png';
import MERNAuth from './images/Optimized-mern-stack-auth-login.png';
import MyTime from './images/Optimized-MyTime.png';
import RandomFeatureSite from './images/Optimized-random-feature-site.png';
import CompleteIt from './images/Optimized-complete-it.png';
import NightAtHome from './images/Optimized-a-night-at-home.png';
import Bandsite from './images/Optimized-bandsite.png';
import Brainflix from './images/Optimized-brainflix.png';
import Klaviyo from './images/Optimized-klaviyo.png';
import Zenni from './images/Optimized-zenni.png';
import Samsung from './images/Optimized-samsung.png';
import Arrow from './images/arrow-up-right.svg';
import TypeAnimation from 'react-type-animation';
import Example from './components/example.js';
import HTML from './images/html5.png';
import CSS from './images/css3.png';
import JavaScript from './images/javascript.png';
import C from './images/c.png';
import PHP from './images/php.png';
import jQuery from './images/jquery.png';
import Bootstrap from './images/bootstrap.jpeg';
import TailwindCSS from './images/tailwindcss.png';
import Sass from './images/sass.png';
import ReactImg from './images/react.jpeg';
import ReactNative from './images/react-native.png';
import Redux from './images/redux.png';
import Shopify from './images/shopify.jpg';
import Liquid from './images/liquid.png';
import Node from './images/node.png';
import Express from './images/express.png';
import MySQL from './images/mysql.jpg';
import MongoDb from './images/mongodb.jpg';
import Laravel from './images/laravel.png';
import Jira from './images/jira.png';
import GitFlow from './images/git-flow.png';
import Asana from './images/asana.jpg';
import Figma from './images/figma.png';
import LeftCurly from './images/curly-bracket-left.png';
import RightCurly from './images/curly-bracket-right.png';

function App() {
  const [isShown, setIsShown] = useState(false);

  const ExampleComponent = () => {
    return (
      <TypeAnimation
        cursor={true}
        sequence={['My name is Baldeep', 1000, 'I am a Full Stack Web Developer', 1000]}
        wrapper="h1"
        repeat={Infinity}
        className="type-animation"
      />

    );
  };

  const showImage = () => {
      setIsShown(true);
      console.log('Is Shown');
  }
  const hideImage = () => {
      setIsShown(false);
      console.log('Is NOT Shown');
  }


  return (
    <div className="App">

      {/* Header Section Start */}
      <section>
        <header className="header">
          <h1>Welcome to my Portfolio Site</h1>
          <ExampleComponent/>
        </header>
      </section>
      {/* Header Section End */}

      {/* (-------------------------------------------------) */}

      {/* Collapse Section Start */}
      <section>
        <div className="collapse__element--container">
          <Example />
        </div>
      </section>
      {/* Collapse Section End */}

      {/* (------------------------------------------------------) */}

      {/* Projects Section Start */}
        <section>
                <div id="projects" className="projects__header">
                  <h1 className="projects__header--h1">FRONT END</h1>
                </div>
                <div className="project__container container-fluid">
                  <div className="row">
                        {/* Column 1 */}
                          {/* <div className="col-sm p-2"> */}
                          {/* Restaurant Site */}
                          <div className="col-sm p-2">
                            <a target="_blank" rel="noreferrer" href="https://restaurant-site-april2023.netlify.app" className="project__wrapper card p-2 m-4">
                              <div className="project__img-container d-flex justify-content-center p-2">
                                  <img className="project__img img-fluid" src={RestaurantSite} alt=""/>
                              </div>
                              <div className="project__info--container p-2">
                                <div className="project__info--header-container mb-2">
                                  <h1 className="project__info--header">Restaurant Site 2023</h1>
                                </div>
                                <ul className="project__info--ul">
                                <h5 className="project__info--h5">HTML, CSS and Vanilla JavaScript</h5>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Took inspiration from a popular Restaurant's site. Large Hero Video I edited myself.</p></li>
                                </ul>
                                <div className="project__info--link-container">
                                  <a target="_blank" rel="noreferrer" href="https://restaurant-site-april2023.netlify.app" className="project__info--link m-1">
                                    <p className="project__info--link-p">View project</p>
                                  </a>
                                </div>
                              </div>
                            </a>
                          </div>
                          {/* HOA Legal Site */}
                          <div className="col-sm p-2">
                            <a target="_blank" rel="noreferrer" href="https://hoa-legal-site.netlify.app" className="project__wrapper card p-2 m-4">
                              <div className="project__img-container d-flex justify-content-center p-2">
                                  <img className="project__img img-fluid" src={HOALegalSite} alt=""/>
                              </div>
                              <div className="project__info--container p-2">
                                <div className="project__info--header-container mb-2">
                                  <h1 className="project__info--header">HOA Legal Site</h1>
                                </div>
                                <ul className="project__info--ul">
                                <h5 className="project__info--h5">HTML, CSS and Vanilla JavaScript</h5>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Made from scratch in HTML, CSS & Javascript by taking inspiration from a Wordpress Site</p></li>
                                </ul>
                                <div className="project__info--link-container">
                                  <a target="_blank" rel="noreferrer" href="https://hoa-legal-site.netlify.app" className="project__info--link m-1">
                                    <p className="project__info--link-p">View project</p>
                                  </a>
                                </div>
                              </div>
                            </a>
                          </div>
                  </div>{/*end first row*/}
                  <div className="row">      
                          {/* Bandsite */}
                          <div className="col-sm p-2">
                            <a target="_blank" rel="noreferrer" href="https://baldeep-bandsite.netlify.app" className="project__wrapper card p-2 m-4">
                              <div className="project__img-container d-flex justify-content-center p-2">
                                  <img className="project__img img-fluid" src={Bandsite} alt=""/>
                              </div>
                              <div className="project__info--container p-2">
                                <div className="project__info--header-container mb-2">
                                  <h1 className="project__info--header">Bandsite</h1>
                                </div>
                                <ul className="project__info--ul">
                                <h5 className="project__info--h5">HTML, CSS and Vanilla JavaScript</h5>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Styled from provided Mock-ups</p></li>
                                {/* <li><img className="project__info--arrow" src={Arrow}/><p>JavaScript Slider</p></li>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Contact Form with embedded Google Maps</p></li> */}
                                </ul>
                                <div className="project__info--link-container">
                                  <a target="_blank" rel="noreferrer" href="https://baldeep-bandsite.netlify.app" className="project__info--link m-1">
                                    <p className="project__info--link-p">View project</p>
                                  </a>
                                </div>
                              </div>
                            </a>
                          </div>
                          {/* Brainflix */}
                          <div className="col-sm p-2">
                            <a target="_blank" rel="noreferrer" href="https://brainflix-baldeep.netlify.app" className="project__wrapper card p-2 m-4">
                              <div className="project__img-container d-flex justify-content-center p-2">
                                  <img className="project__img img-fluid" src={Brainflix} alt=""/>
                              </div>
                              <div className="project__info--container p-2">
                                <div className="project__info--header-container mb-2">
                                  <h1 className="project__info--header">Brainflix</h1>
                                </div>
                                <ul className="project__info--ul">
                                <h5 className="project__info--h5">HTML, SASS and React</h5>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Styled from provided Mock-ups</p></li>
                                {/* <li><img className="project__info--arrow" src={Arrow}/><p>JavaScript Slider</p></li>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Contact Form with embedded Google Maps</p></li> */}
                                </ul>
                                <div className="project__info--link-container">
                                  <a target="_blank" rel="noreferrer" href="https://brainflix-baldeep.netlify.app" className="project__info--link m-1">
                                    <p className="project__info--link-p">View project</p>
                                  </a>
                                </div>
                              </div>
                            </a>
                          </div>
                          {/* Dream Homes */}
                          <div className="col-sm p-2">
                            <a target="_blank" rel="noreferrer" href="https://dream-homes-baldeep.netlify.app" className="project__wrapper card p-2 m-4">
                              <div className="project__img-container d-flex justify-content-center p-2">
                                  <img className="project__img img-fluid" src={DreamHomes} alt=""/>
                              </div>
                              <div className="project__info--container p-2">
                                <div className="project__info--header-container mb-2">
                                  <h1 className="project__info--header">Dream Homes</h1>
                                </div>
                                <ul className="project__info--ul">
                                <h5 className="project__info--h5">HTML, CSS and Vanilla JavaScript</h5>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Styled as I built.</p></li>
                                {/* <li><img className="project__info--arrow" src={Arrow}/><p>JavaScript Slider</p></li>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Contact Form with embedded Google Maps</p></li> */}
                                </ul>
                                <div className="project__info--link-container">
                                  <a target="_blank" rel="noreferrer" href="https://dream-homes-baldeep.netlify.app" className="project__info--link m-1">
                                    <p className="project__info--link-p">View project</p>
                                  </a>
                                </div>
                              </div>
                            </a>
                          </div>
                  </div>{/* End of Row 2 */}
                </div>
        </section>
        <section>
                <div id="projects" className="projects__header">
                  <h1 className="projects__header--h1">API'S and Database</h1>
                </div>
                <div className="project__container container-fluid">
                  <div className="row">
                        {/* Column 1 */}
                          {/* <div className="col-sm p-2"> */}
                          {/* A Night At Home */}
                          <div className="col-sm p-2">
                            <a target="_blank" rel="noreferrer" href="https://a-night-at-home-client-side.netlify.app" className="project__wrapper card p-2 m-4">
                              <div className="project__img-container d-flex justify-content-center p-2">
                                  <img className="project__img img-fluid" src={NightAtHome} alt=""/>
                              </div>
                              <div className="project__info--container p-2">
                                <div className="project__info--header-container mb-2">
                                  <h1 className="project__info--header">A Night At Home</h1>
                                </div>
                                <ul className="project__info--ul">
                                <h5 className="project__info--h5">HTML, SASS, React, Nodejs, Expressjs</h5>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Server on Heroku. May need refresh to wake up.</p></li>
                                {/* <li><img className="project__info--arrow" src={Arrow}/><p>JavaScript Slider</p></li>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Contact Form with embedded Google Maps</p></li> */}
                                </ul>
                                <div className="project__info--link-container">
                                  <a target="_blank" rel="noreferrer" href="https://a-night-at-home-client-side.netlify.app" className="project__info--link m-1">
                                    <p className="project__info--link-p">View project</p>
                                  </a>
                                </div>
                              </div>
                            </a>
                          </div>
                          {/* Blah-bble News */}
                          <div className="col-sm p-2">
                            <a target="_blank" rel="noreferrer" href="https://blah-bble-news.netlify.app" className="project__wrapper card p-2 m-4">
                              <div className="project__img-container d-flex justify-content-center p-2">
                                  <img className="project__img img-fluid" src={BlahbbleNews} alt=""/>
                              </div>
                              <div className="project__info--container p-2">
                                <div className="project__info--header-container mb-2">
                                  <h1 className="project__info--header">Blah-bble News</h1>
                                </div>
                                <ul className="project__info--ul">
                                <h5 className="project__info--h5">HTML, SASS and React</h5>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Uses Geo-Location and approx. 10 more API calls</p></li>
                                {/* <li><img className="project__info--arrow" src={Arrow}/><p>JavaScript Slider</p></li>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Contact Form with embedded Google Maps</p></li> */}
                                </ul>
                                <div className="project__info--link-container">
                                  <a target="_blank" rel="noreferrer" href="https://blah-bble-news.netlify.app" className="project__info--link m-1">
                                    <p className="project__info--link-p">View project</p>
                                  </a>
                                </div>
                              </div>
                            </a>
                          </div>
                          
                          
                      {/* End of Row 1 */}
                  </div>
                </div>
        </section>
        <section>
                <div id="projects" className="projects__header">
                  <h1 className="projects__header--h1">HTML Emails</h1>
                </div>
                <div className="project__container container-fluid">
                  <div className="row">
                        {/* Column 1 */}
                          {/* <div className="col-sm p-2"> */}
                          {/* Klaviyo */}
                          <div className="col-sm p-2">
                            <a target="_blank" rel="noreferrer" href="https://klaviyo-html-email.netlify.app" className="project__wrapper card p-2 m-4">
                              <div className="project__img-container d-flex justify-content-center p-2">
                                  <img className="project__img img-fluid" src={Klaviyo} alt=""/>
                              </div>
                              <div className="project__info--container p-2">
                                <div className="project__info--header-container mb-2">
                                  <h1 className="project__info--header">Klaviyo Marketing Email</h1>
                                </div>
                                <ul className="project__info--ul">
                                <h5 className="project__info--h5">HTML & CSS Tables</h5>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Created using an actual marketing email as reference</p></li>
                                {/* <li><img className="project__info--arrow" src={Arrow}/><p>JavaScript Slider</p></li>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Contact Form with embedded Google Maps</p></li> */}
                                </ul>
                                <div className="project__info--link-container">
                                  <a target="_blank" rel="noreferrer" href="https://klaviyo-html-email.netlify.app" className="project__info--link m-1">
                                    <p className="project__info--link-p">View project</p>
                                  </a>
                                </div>
                              </div>
                            </a>
                          </div>
                          {/* Zenni */}
                          <div className="col-sm p-2">
                            <a target="_blank" rel="noreferrer" href="https://zenni-html-email.netlify.app" className="project__wrapper card p-2 m-4">
                              <div className="project__img-container d-flex justify-content-center p-2">
                                  <img className="project__img img-fluid" src={Zenni} alt=""/>
                              </div>
                              <div className="project__info--container p-2">
                                <div className="project__info--header-container mb-2">
                                  <h1 className="project__info--header">Zenni Marketing Email</h1>
                                </div>
                                <ul className="project__info--ul">
                                <h5 className="project__info--h5">HTML & CSS Tablest</h5>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Created using an actual marketing email as reference</p></li>
                                {/* <li><img className="project__info--arrow" src={Arrow}/><p>JavaScript Slider</p></li>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Contact Form with embedded Google Maps</p></li> */}
                                </ul>
                                <div className="project__info--link-container">
                                  <a target="_blank" rel="noreferrer" href="https://zenni-html-email.netlify.app" className="project__info--link m-1">
                                    <p className="project__info--link-p">View project</p>
                                  </a>
                                </div>
                              </div>
                            </a>
                          </div>
                          {/* Samsung */}
                          <div className="col-sm p-2">
                            <a target="_blank" rel="noreferrer" href="https://samsung-html-email.netlify.app" className="project__wrapper card p-2 m-4">
                              <div className="project__img-container d-flex justify-content-center p-2">
                                  <img className="project__img img-fluid" src={Samsung} alt=""/>
                              </div>
                              <div className="project__info--container p-2">
                                <div className="project__info--header-container mb-2">
                                  <h1 className="project__info--header">Samsung Marketing Email</h1>
                                </div>
                                <ul className="project__info--ul">
                                <h5 className="project__info--h5">HTML & CSS Tablest</h5>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Created using an actual marketing email as reference</p></li>
                                {/* <li><img className="project__info--arrow" src={Arrow}/><p>JavaScript Slider</p></li>
                                <li><img className="project__info--arrow" src={Arrow}/><p>Contact Form with embedded Google Maps</p></li> */}
                                </ul>
                                <div className="project__info--link-container">
                                  <a target="_blank" rel="noreferrer" href="https://samsung-html-email.netlify.app" className="project__info--link m-1">
                                    <p className="project__info--link-p">View project</p>
                                  </a>
                                </div>
                              </div>
                            </a>
                          </div>
                      {/* End of Row 1 */}
                  </div>
                </div>
        </section>


        {/* </div> */}
      {/* Projects Section End */}

      {/* ------------------------------------------------------- */}

      {/* Tech Stacks Start */}
      <section id="goodbye">
          <div className="tech-stacks__container container mx-auto">

            <h1 className="tech-stack__header d-flex justify-content-center py-5">The Tech Stacks I use are...</h1>
            <div className="container">
              {/* Row 1 */}
              <div 
                className="row d-flex justify-content-center py-3"
                onMouseEnter={()=>{setIsShown(true)}}
                onMouseLeave={()=>{setIsShown(false)}}
                >
                <div className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={HTML} alt="" />) : (<div>HTML</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={CSS} alt="" />) : (<div>CSS</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={jQuery} alt="" />) : (<div>jQuery</div>)}
                </div>
              </div>
              {/* Row 2 */}
              <div 
                className="row d-flex justify-content-center py-3"
                onMouseEnter={()=>{setIsShown(true)}}
                onMouseLeave={()=>{setIsShown(false)}}
                >
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={JavaScript} alt="" />) : (<div>JavaScript</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={ReactImg} alt="" />) : (<div>React</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={ReactNative} alt="" />) : (<div>React Native</div>)}
                </div>
              </div>
              {/* Row 3 */}
              <div 
                className="row d-flex justify-content-center py-3"
                onMouseEnter={()=>{setIsShown(true)}}
                onMouseLeave={()=>{setIsShown(false)}}
                >
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={Express} alt="" />) : (<div>Expressjs</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={Node} alt="" />) : (<div>Nodejs</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={MongoDb} alt="" />) : (<div>MongoDb</div>)}
                </div>
              </div>
              {/* Row 4 */}
              <div 
                className="row d-flex justify-content-center py-3"
                onMouseEnter={()=>{setIsShown(true)}}
                onMouseLeave={()=>{setIsShown(false)}}
                >
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={Sass} alt="" />) : (<div>Sass</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={Redux} alt="" />) : (<div>Redux</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={Bootstrap} alt="" />) : (<div>BootStrap</div>)}
                </div>
              </div>
              {/* Row 5 */}
              {/* <div 
                className="row d-flex justify-content-center py-3"
                onMouseEnter={()=>{setIsShown(true)}}
                onMouseLeave={()=>{setIsShown(false)}}
                >
              <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={TailwindCSS} alt="" />) : (<div>TailwindCSS</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={Laravel} alt="" />) : (<div>Laravel</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={MySQL} alt="" />) : (<div>MySQL</div>)}
                </div>
              </div> */}
              {/* Row 6 */}
              <div 
                className="row d-flex justify-content-center py-3"
                onMouseEnter={()=>{setIsShown(true)}}
                onMouseLeave={()=>{setIsShown(false)}}
                >
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={C} alt="" />) : (<div>C#</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={PHP} alt="" />) : (<div>PHP</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={Shopify} alt="" />) : (<div>Shopify</div>)}
                </div>
              </div>
              {/* Row 7 */}
              {/* <div 
                className="row d-flex justify-content-center py-3"
                onMouseEnter={()=>{setIsShown(true)}}
                onMouseLeave={()=>{setIsShown(false)}}
                >
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={Liquid} alt="" />) : (<div>Liquid</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={GitFlow} alt="" />) : (<div>GitFlow</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={Asana} alt="" />) : (<div>Asana</div>)}
                </div>
              </div> */}
              {/* Row 8 */}
              <div 
                className="row d-flex justify-content-center py-3"
                onMouseEnter={()=>{setIsShown(true)}}
                onMouseLeave={()=>{setIsShown(false)}}
                >
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={Jira} alt="" />) : (<div>Jira</div>)}
                </div>
                <div 
                  className="col tech-stack__p">
                  {isShown ? (<img className="tech-stack__img" src={Figma} alt="" />) : (<div>Figma</div>)}
                </div>
                <div className="col tech-stack__p">
                  more to learn...
                </div>
              </div>
            </div>
          </div>              
      </section>
      {/* Tech Stacks End */}
      <section>
        <div className="goodbye">
          <h2 className="goodbye__h2">
            Thank you for taking the time to look at my portfolio website. I look forward to hearing from you and possibly working together.
          </h2>
        </div>
      </section>
      <footer>
        <div className='container mx-auto footer__container'>
          <div className="footer__comment--wrapper">
              <div className="col-sm-0"><img className="footer__comment--img" src={LeftCurly} alt="" /></div>
                <p className="footer__commented-out col-sm-3">-Canadian & US Citizen-</p>
                <p className="footer__commented-out col-sm-3">-Seeking first position-</p>
                <p className="footer__commented-out col-sm-2">-Remote or on Site-</p>
              <div className="col-sm-0"><img className="footer__comment--img" src={RightCurly} alt="" /></div>
          </div>
          <div className="row footer__info--row">
            <div className="col footer__info--col">
              <p className="footer__info">Baldeep Parihar</p>
              <p className="footer__info">604 250 9617</p>
              <p className="footer__info">bparihar1@yahoo.com</p>
            </div>
            <div className="col footer__link--container">
              <a className="footer__link" target="_blank" href="https://www.linkedin.com/in/baldeepparihar">
              linkedin.com/in/baldeepparihar
              </a>
              <a className="footer__link" target="_blank" href="https://www.github.com/baldeepparihar">
              github.com/baldeepparihar
              </a>
            </div>
            <div className="col footer__link--container">
              <a className="footer__link" target="_blank" href="https://twitter.com/DeepTech_bal?s=09">
              Twitter@DeepTech_bal
              </a>
              <a className="footer__link" target="_blank" href="https://www.instagram.com/deeptechdesign/">
              Instagram@DeepTechDesign
              </a>
              <a className="footer__link" target="_blank" href="https://www.youtube.com/channel/UC-ukMfnOFvVKppRl-JNH1Aw/featured">
              YouTube@DeepTechDesign
              </a>
            </div>
          </div>
          <div className="row d-flex justify-content-center copyright">All rights reserved. Copyright &copy; Baldeep Parihar, May 20th, 2022 'Single Page Portfolio site' developed by Baldeep Parihar.</div>
        </div>
      </footer>

    </div>
  );
}

export default App;
