import React, { useState } from 'react';
import { Button, Fade } from 'react-bootstrap';
import '../App.css';

function Example() {
    const [open, setOpen] = useState(false);
  
    return (
      <div className='fade-wrapper'>
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="fade-text"
          aria-expanded={open}
          className="collapse__btn"
        >
          Click for Contact Info
        </Button>
        <Fade in={open} className="fade-element">
          <div id="fade-text">
            <h3>Baldeep Parihar</h3>
            <a className="fade-element__links" href="mailto: bparihar1@yahoo.com">bparihar1@yahoo.com</a>
            <a className="fade-element__links" href="tel: 604-250-9617">604-250-9617</a>
            <a className="fade-element__links" target="_blank" href="https://www.linkedin.com/in/baldeepparihar">LinkedIn</a>
            <a className="fade-element__links" target="_blank" href="https://www.github.com/baldeepparihar">GitHub</a>
            <a className="fade-element__links" target="_blank" href="https://www.deeptechdesign.com">function RecursiveCallToWebsite(websiteLink) => return websiteLink;</a>
          </div>
        </Fade>
      </div>
    );
  }

  export default Example;